import React from 'react';
import Helmet from 'react-helmet';
import SEO from 'components/SEO';
import Intro from 'templates/Cases/Intro';
import Heading from 'components/Heading';
import { StyledFlexColumn, StyledRelativeDiv } from './styled';

const Cases = ({ page, projects, pageContext }) => {
  const { seo } = page;
  const { baseURL, locale } = pageContext;
  return (
    <StyledFlexColumn>
      <SEO
        meta_title={
          locale == 'pt'
            ? seo?.metaTitle
            : 'ateliware cases | we turn challenges into products'
        }
        meta_description={
          locale == 'pt'
            ? seo?.metaDescription
            : 'Here at ateliware we work with products and not with projects. Machine learning, operating systems, management platforms, SaaS, ERP implementation: get to know our cases.'
        }
        og_title={
          locale == 'pt'
            ? seo?.ogTitle
            : 'ateliware cases | we turn challenges into products'
        }
        og_description={
          locale == 'pt'
            ? seo?.ogDescription
            : 'Here at ateliware we work with products and not with projects. Machine learning, operating systems, management platforms, SaaS, ERP implementation: get to know our cases.'
        }
        og_type={seo?.ogType}
        og_image={seo?.ogImage?.url}
        lang={locale}
      />

      <Heading
        title={page.title}
        description={page.description}
        withXMargin
      />

      <StyledRelativeDiv>
        <Intro page={page} projects={projects} baseURL={baseURL} />
      </StyledRelativeDiv>

      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context":"https://schema.org",
              "@type":"Organization",
              "name":"ateliware",
              "legalName":"ateliware",
              "url":"http://ateliware.com/cases",
              "logo":"https://media.graphassets.com/gJsd71c5SGh1RyV8tIrV",
              "address":{
                "@type":"PostalAddress",
                "streetAddress":"Rua Pasteur, 463",
                "addressLocality":"Curitiba",
                "addressRegion":"Paraná",
                "postalCode":"80250-080",
                "addressCountry":"Brasil"
              },
              "contactPoint":{
                "@type":"ContactPoint",
                "contactType":"Contato",
                "telephone":"[+55 41 3010-2275]",
                "email":"hello@ateliware.com"
              },
              "sameAs":[
                "https://github.com/ateliware",
                "https://www.linkedin.com/company/ateliware/",
                "https://twitter.com/ateliware",
                "https://www.youtube.com/channel/UCxPjijyar2emdKLPpOrugOw",
                "https://www.meetup.com/ateliware/",
                "https://goo.gl/maps/gLPc35mF3js7rLnL8"
              ]
            }
          `}
        </script>
      </Helmet>
    </StyledFlexColumn>
  );
};

export default Cases;
