import styled, { css } from 'styled-components';

export const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledRelativeDiv = styled.div`
  position: relative;
  min-height: 700px;
`;
