import styled, { css } from 'styled-components';

export const StyledContainer = styled.section`
  margin: ${(props) =>
    props.withXMargin
      ? '3rem 6rem 3rem 6rem'
      : '3rem 0rem 3rem 0rem'};

  @media screen and (max-width: 980px) {
    margin: ${(props) =>
      props.withXMargin
        ? '1.5rem 3rem 1.5rem 3rem'
        : '1.5rem 0rem 1.5rem 0rem'};
  }
`;

export const StyledDescription = styled.p`
  font-weight: 300;
`;
