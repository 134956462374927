import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';
import IconArrow from 'assets/images/cases/icons/arrow.svg';

export const StyledIntro = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  @media screen and (max-width: 1040px) {
    height: auto;
  }
`;

export const StyledIntroContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65%;
  height: 100%;
  padding: 50px;

  @media screen and (max-width: 1040px) {
    width: 100%;
    padding: 150px 50px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }

  @media screen and (max-width: 520px) {
    display: none;
  }
`;

export const StyledIntroTitle = styled.h1`
  display: inline-block;
  max-width: 80%;
  font-family: ${fonts.primary};
  font-size: 98px;
  font-weight: 700;
  color: ${colors.gray300};
  line-height: 1.1;

  ${(props) =>
    props.active === 'true' &&
    css`
      opacity: 0;
      visibility: hidden;
    `}

  @media screen and (max-width: 1040px) {
    font-size: 100px;
    opacity: 1;
    visibility: visible;
  }

  @media screen and (max-width: 960px) {
    font-size: 80px;
  }

  @media screen and (max-width: 768px) {
    font-size: 60px;
  }

  @media screen and (max-width: 580px) {
    font-size: 50px;
  }

  @media screen and (max-width: 520px) {
    font-size: 42px;
  }

  @media screen and (max-width: 460px) {
    font-size: 36px;
  }

  @media screen and (max-width: 420px) {
    font-size: 32px;
  }
`;

export const StyledIntroCases = styled.div`
  opacity: 0;
  visibility: hidden;

  ${(props) =>
    props.active === 'true' &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  @media screen and (max-width: 1040px) {
    opacity: 1;
    visibility: visible;
  }
`;

export const StyledIntroProject = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 65%;
  height: 100%;
  padding: 50px;
  text-decoration: none;
  background-color: ${colors.red300};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0;
  visibility: hidden;

  ${(props) =>
    props.bg &&
    css`
      background-image: url('https://media.graphassets.com/resize=w:1200,fit:clip/output=format:webp/compress/${props.bg}');
    `};

  ${(props) =>
    props.active === 'true' &&
    css`
      opacity: 1;
      visibility: visible;
      box-shadow: 0px 4px 56px 0px #00000040;
    `};

  &:hover {
    h3 {
      &:after {
        margin-left: 30px;
      }
    }
  }

  @media screen and (max-width: 1040px) {
    position: static;
    width: 100%;
    padding: 150px 50px;
    opacity: 1;
    visibility: visible;
    transition: all 0.2s ease-out;

    &:hover {
      padding: 160px 50px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 100px 50px;

    &:hover {
      padding: 110px 50px;
    }
  }

  @media screen and (max-width: 520px) {
    padding: 50px;

    &:hover {
      padding: 60px 50px;
    }
  }
`;

export const StyledIntroContent = styled.div`
  h2 {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    font-family: ${fonts.primary};
    font-size: 100px;
    line-height: 100px;
    color: ${colors.white};
    width: min-content;

    &:after {
      display: block;
      width: 150px;
      height: 100px;
      margin-left: 20px;
      background-image: url('${IconArrow}');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 95px auto;
      transition: all 0.2s ease-out;
      content: '';
    }
  }

  p {
    max-width: 50%;
    font-size: 24px;
    line-height: 40px;
    color: ${colors.white};
  }

  @media screen and (max-width: 1440px) {
    h2 {
      font-size: 100px;

      &:after {
        display: block;
        width: 100px;
        height: 100px;
        margin-left: 20px;
        background-size: 75px auto;
        content: '';
      }
    }
  }

  @media screen and (max-width: 1220px) {
    h2 {
      font-size: 90px;

      &:after {
        display: block;
        width: 100px;
        height: 100px;
        margin-left: 20px;
        background-size: 75px auto;
        content: '';
      }
    }
  }

  @media screen and (max-width: 1080px) {
    h2 {
      font-size: 80px;

      &:after {
        display: block;
        width: 100px;
        height: 100px;
        margin-left: 20px;
        background-size: 75px auto;
        content: '';
      }
    }
  }

  @media screen and (max-width: 768px) {
    h2 {
      font-size: 60px;
    }

    p {
      max-width: 100%;
    }
  }

  @media screen and (max-width: 580px) {
    h2 {
      font-size: 40px;

      &:after {
        display: block;
        width: 50px;
        height: 50px;
        margin-left: 20px;
        background-size: 45px auto;
        content: '';
      }
    }
  }

  @media screen and (max-width: 420px) {
    h2 {
      font-size: 28px;

      &:after {
        display: block;
        width: 30px;
        height: 30px;
        margin-left: 20px;
        background-size: 25px auto;
        content: '';
      }
    }

    p {
      font-size: 16px;
    }
  }
`;

export const StyledIntroNav = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 35%;
  padding: 0 50px;
  align-self: flex-start;

  @media screen and (max-width: 1040px) {
    display: none;
  }
`;

export const StyledIntroList = styled.ul`
  margin: 0;
  padding: 0;
  width: 300px;
  list-style-type: none;
`;

export const StyledIntroItem = styled.li`
  margin-bottom: 24px;
  h3 {
    margin: 0;
    font-family: ${fonts.primary};
    font-size: 32px;
    font-weight: 300;
    height: 40px;
    color: ${colors.gray300};
    text-align: left;

    &:first-child {
      margin-top: 0;
    }

    a {
      display: inline-block;
      color: ${colors.gray300};
      text-decoration: none;

      &:hover {
        font-weight: 700;
      }

      ${(props) =>
        props.active === 'true' &&
        css`
          font-weight: 700;
        `}
    }
  }
`;
