import React, { useState } from 'react';
import { Link } from 'gatsby';
import {
  StyledIntro,
  StyledIntroContainer,
  StyledIntroTitle,
  StyledIntroCases,
  StyledIntroProject,
  StyledIntroContent,
  StyledIntroNav,
  StyledIntroList,
  StyledIntroItem
} from './styled';

const Intro = ({ page, projects, baseURL }) => {
  const [hoverCase, setHoverCase] = useState(null);

  return (
    <StyledIntro>
      <StyledIntroContainer>
        <StyledIntroTitle
          active={hoverCase ? `${hoverCase !== null}` : undefined}
        >
          {page.content1Title}
        </StyledIntroTitle>
      </StyledIntroContainer>

      <StyledIntroCases
        active={hoverCase ? `${hoverCase !== null}` : undefined}
      >
        {projects.map((project, index) => {
          const {
            id,
            slug,
            title,
            description,
            externalLpUrl,
            featuredImage
          } = project;

          let toUrl = `${baseURL}cases/${slug}`;

          if (externalLpUrl) {
            toUrl = externalLpUrl;
          }

          return (
            <StyledIntroProject
              to={toUrl}
              title={title}
              active={
                hoverCase ? `${hoverCase === index + 1}` : undefined
              }
              key={id}
              bg={featuredImage.handle}
            >
              <StyledIntroContent>
                <h2>{title}</h2>
                <p>{description}</p>
              </StyledIntroContent>
            </StyledIntroProject>
          );
        })}
      </StyledIntroCases>

      <StyledIntroNav>
        <StyledIntroList>
          {projects.map((project, index) => {
            const { id, slug, title, externalLpUrl } = project;

            let toUrl = `${baseURL}cases/${slug}`;

            if (externalLpUrl) {
              toUrl = externalLpUrl;
            }

            return (
              <StyledIntroItem
                onMouseOver={() => setHoverCase(index + 1)}
                active={
                  hoverCase ? `${hoverCase === index + 1}` : undefined
                }
                key={id}
              >
                <h3>
                  <Link to={toUrl}>{title}</Link>
                </h3>
              </StyledIntroItem>
            );
          })}
        </StyledIntroList>
      </StyledIntroNav>
    </StyledIntro>
  );
};

export default Intro;
