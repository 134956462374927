import React from 'react';
import { StyledContainer, StyledDescription } from './styled';
import { StyledDashedTitle } from 'assets/global/styled';

const Heading = ({ title, description, ...otherProps }) => {
  return (
    <StyledContainer {...otherProps}>
      <StyledDashedTitle>{title}</StyledDashedTitle>
      <StyledDescription>{description}</StyledDescription>
    </StyledContainer>
  );
};

export default Heading;
