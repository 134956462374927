import React from 'react';
import { graphql } from 'gatsby';
import Header from 'components/Header';
import Cases from 'templates/Cases';
import { StyledFlexColumn } from './styled';

const CasesPage = ({
  data: {
    ateliware: { page, projects, headerMenu }
  },
  pageContext
}) => {
  return (
    <StyledFlexColumn>
      <Header menu={headerMenu} pageContext={pageContext} />
      <Cases
        page={page}
        projects={projects}
        pageContext={pageContext}
      />
    </StyledFlexColumn>
  );
};

export const caseQuery = graphql`
  query CasePageQuery($idPage: ID!, $locale: [GCMS_Locale!]!) {
    ateliware {
      page(where: { id: $idPage }, locales: $locale) {
        id
        slug
        title
        description
        locale
        content1Title
        seo(locales: pt) {
          metaTitle
          metaDescription
          ogTitle
          ogDescription
          ogType
          ogImage {
            url
          }
        }
      }
      projects(locales: $locale) {
        id
        slug
        title
        description
        externalLpUrl
        featuredImage(locales: pt) {
          handle
          width
          height
          title
          alt
          url
        }
      }
      headerMenu: menu(where: { title: "header" }, locales: $locale) {
        locale
        callToAction
        menuItems {
          title
          slug
          locale
          goTo
          externalLink
          subMenuItems {
            title
            slug
            locale
            goTo
            externalLink
          }
        }
      }
    }
  }
`;

export default CasesPage;
